export const BLOCKS_GET_LATEST_START = 'BLOCKS_GET_LATEST_START';
export const BLOCKS_GET_LATEST_SUCCESS = 'BLOCKS_GET_LATEST_SUCCESS';
export const BLOCKS_GET_LATEST_FAILURE = 'BLOCKS_GET_LATEST_FAILURE';

export const BLOCKS_GET_START = 'BLOCKS_GET_START';
export const BLOCKS_GET_SUCCESS = 'BLOCKS_GET_SUCCESS';
export const BLOCKS_GET_FAILURE = 'BLOCKS_GET_FAILURE';

export const BLOCK_GET_START = 'BLOCK_GET_START';
export const BLOCK_GET_SUCCESS = 'BLOCK_GET_SUCCESS';
export const BLOCK_GET_FAILURE = 'BLOCK_GET_FAILURE';

export const VALIDATORS_GET_START = 'VALIDATORS_GET_START';
export const VALIDATORS_GET_SUCCESS = 'VALIDATORS_GET_SUCCESS';
export const VALIDATORS_GET_FAILURE = 'VALIDATORS_GET_FAILURE';

export const VALIDATORS_GET_LATEST_START = 'VALIDATORS_GET_LATEST_START';
export const VALIDATORS_GET_LATEST_SUCCESS = 'VALIDATORS_GET_LATEST_SUCCESS';
export const VALIDATORS_GET_LATEST_FAILURE = 'VALIDATORS_GET_LATEST_FAILURE';

export const VALIDATOR_GET_START = 'VALIDATOR_GET_START';
export const VALIDATOR_GET_SUCCESS = 'VALIDATOR_GET_SUCCESS';
export const VALIDATOR_GET_FAILURE = 'VALIDATOR_GET_FAILURE';

export const ACCOUNT_GET_START = 'ACCOUNT_GET_START';
export const ACCOUNT_GET_SUCCESS = 'ACCOUNT_GET_SUCCESS';
export const ACCOUNT_GET_FAILURE = 'ACCOUNT_GET_FAILURE';

export const TRANSACTION_GET_START = 'TRANSACTION_GET_START';
export const TRANSACTION_GET_SUCCESS = 'TRANSACTION_GET_SUCCESS';
export const TRANSACTION_GET_FAILURE = 'TRANSACTION_GET_FAILURE';

export const TRANSACTIONS_GET_START = 'TRANSACTIONS_GET_START';
export const TRANSACTIONS_GET_SUCCESS = 'TRANSACTIONS_GET_SUCCESS';
export const TRANSACTIONS_GET_FAILURE = 'TRANSACTIONS_GET_FAILURE';

export const SEARCH_START = 'SEARCH_START';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

export const MIGRATION_FETCH_START = 'MIGRATION_FETCH_START';
export const MIGRATION_FETCH_SUCCESS = 'MIGRATION_FETCH_SUCCESS';
export const MIGRATION_FETCH_FAILURE = 'MIGRATION_FETCH_FAILURE';

export const MIGRATION_SUBMIT_START = 'MIGRATION_SUBMIT_START';
export const MIGRATION_SUBMIT_SUCCESS = 'MIGRATION_SUBMIT_SUCCESS';
export const MIGRATION_SUBMIT_FAILURE = 'MIGRATION_SUBMIT_FAILURE';
